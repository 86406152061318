<template>
  <div class="rn_my_tit_3">
    램 장터
    <div class="search">
      <div class="input_box_n">
        <input
          type="text"
          name=""
          v-model="searchText"
          @keydown.enter="goSearch"
          placeholder="상품을 검색해주세요."
        />
      </div>
      <router-link to="" @click.native="goSearch"
        ><img src="@/assets/images/sub/alarm_search_n_bt.png" alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/goods_search?search=${this.searchText}`);
    },
  },
};
</script>

<style></style>

<template>
  <div class="rn_my_wrap">
    <GoodsSearch />
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <!---->
      <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="/market_category_list"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div>
        <div class="cate_right">
          <!-- -->
          <GoodsCategory
            :category="categories"
            :id="id"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
          <!-- -->
        </div>
      </div>
      <!---->
      <div class="rn_ms_box_4">
        <div class="sel_sort">
          <div>
            <router-link
              to=""
              class="list_sort on"
              @click.native="listSort('newly', $event)"
              >최신순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('popular_sales', $event)"
              >판매순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('reviewer', $event)"
              >리뷰수순</router-link
            >
          </div>
        </div>
        <div class="sect_location">
          <span @click="goMainList()">상품 ></span>
          <template v-for="(name, index) in categoryName">
            <span
              to=""
              :key="index"
              :id="`${categoryName.length}_${index}`"
              :data-level="name.level"
              :data-id="name.id"
              v-if="categoryName.length - 1 > index"
              @click="goCategory($event)"
            >
              {{ name.title }} >
            </span>
            <b
              v-if="categoryName.length - 1 <= index"
              :key="index"
              :id="`${categoryName.length}_${index}`"
              :data-id="name.id"
              :data-level="name.level"
              @click="goCategory($event)"
            >
              {{ name.title }}
            </b>
          </template>
        </div>
        <div class="sub_list" v-if="listArray.length > 0">
          <template v-for="(items, index) in listArray">
            <div :key="index">
              <div class="box" v-for="item in items" :key="item.id">
                <div class="txt">
                  <router-link
                    to=""
                    @click.native="goLowCategory(item.id, item.level)"
                  >
                    <div class="left">{{ items[0].category_name }}</div>
                    <div class="right">
                      <img src="@/assets/images/sub/my_move_ico.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <router-link
                  :to="`/market_goods_detail/${id}/${item.goods_seq}`"
                >
                  <div class="img">
                    <img
                      :src="item.image"
                      @error="replaceImg"
                      :alt="item.goods_name"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="no_item">
          <p>카테고리 상품 준비중 입니다.</p>
        </div>
        <div
          class="rn_more"
          v-if="parseInt(orgTotalCnt) > parseInt(categoryPage)"
        >
          <router-link to="" @click.native="getMarketSubList()">
            더보기<img src="@/assets/images/sub/my_down_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import GoodsCategory from "@/components/shop/menu/GoodsCategory";
import GoodsSearch from "@/components/shop/menu/GoodsSearch.vue";
export default {
  data() {
    return {
      visit: 0,
      first: true,
      id: this.$route.params.id,
      level: this.$route.params.level,
      ordId: this.$route.params.id,
      ordLevel: this.$route.params.level,
      lowId: 0,
      categoryPage: 0,
      page: 0,
      sort: "newly",
      keyword: "",
      orgTotalCnt: 0,
      tmpId: null,
      tmpCategoryPage: 1, //이것은 더보기를 눌렀을때 하위 카테고리 갯수와 orgTotalCnt를 비교하기 위해서..
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      searchText: "",
    };
  },
  components: {
    MainMenu,
    GoodsCategory,
    GoodsSearch,
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", [
      "result",
      "msg",
      "categories",
      "listArray",
      "totalPage",
      "categoryName",
    ]),
    categoryCode() {
      let index = this.categoryName.findIndex(
        (x) => parseInt(x.id) == parseInt(this.id)
      );

      return this.categoryName[index]["category_code"].substr(0, 4);
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    goMainList() {
      this.$router.push("/market_main_list");
    },
    listSort(sort, event) {
      [...document.querySelectorAll(".list_sort")].map((sort) =>
        sort.classList.remove("on")
      );
      event.target.classList.add("on");
      this.sort = sort;
      // if (parseInt(this.lowId) !== 0) {
      //   this.level = parseInt(this.level) - 1; //변경된 하위 레벨보다 1 상위 레벨로 변경
      //   this.lowId = 0; //하위 카테고리 번호 초기화
      // }
      this.getMarketSubList(true, false);
    },
    getDataSearch() {
      //전혀 다른 검색화면
    },
    goCategory(event) {
      const id = event.target.dataset.id;
      const level = event.target.dataset.level;
      if (parseInt(level) <= 1) {
        this.$router.push(`/market_main_list/${id}`);
      } else {
        this.goLowCategory(id, level);
      }
    },
    goLowCategory(id, level) {
      this.lowId = id;
      this.level = parseInt(level);
      this.first = false; //하위 카테고리 이동 시 처음 여부 false로
      if (parseInt(level) >= 5) {
        this.$router.push(`/market_goods_list/${level}/${id}`);
        return false;
      }
      this.getMarketSubListThen(true);
    },
    getGoodsCategory(id) {
      // console.log(id);
      if (parseInt(this.level) <= 2) {
        //레벨 2일때만 예외로 처리
        this.$router.push(`/market_main_list/${id}`);
      } else {
        //아니면 처음 실행 되었는지 확인하고 아니면 이동
        if (!this.first) {
          this.$router.push(`/market_main_list/${id}`);
        }
      }
      this.first = false;
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getMarketSubList(true, true);
    },
    async getMarketSubListThen(reset = false) {
      await this.$store.dispatch("shop/getMarketSubList", {
        id: this.id,
        low_id: this.lowId,
        level: this.level,
        sort: this.sort,
        categrory_page: 0,
        page: this.page,
        reset,
      });
      // if (!this.result) {
      //   this.$toast.default(this.msg);
      //   this.level = this.level - 1;
      //   this.lowId = 0;
      // }
      //현재 카테고리에서 하위 카테고리 리스트를 불러오면 현재 카테고리 id는 this.id에 그대로 남고 this.lowid에 새로운 id가 담겨서 나간다.
      //this.lowid가 0 이 아니라는건 현재 카테고리에서 하위 카테고리 리스트를 요청 하것
      if (parseInt(this.lowId) !== 0) {
        //현재 카테고리에 하위 카테고리를 호출했을때 데이터가 있으면 true로 들어옴. 하지만 true 이면 페이지가 변경되어서 사실상 false인지 확인한다.
        if (this.result) {
          this.orgTotalCnt = this.totalPage;
        }
      }
    },
    async getMarketSubList(reset = false, category = true) {
      if (reset) {
        this.categoryPage = 0;
      }

      await this.$store.dispatch("shop/getMarketSubList", {
        id: this.ordId,
        low_id: this.lowId,
        level: this.level,
        sort: this.sort,
        categrory_page: this.categoryPage,
        page: this.page,
        perpage: this.pagePer,
        reset,
      });

      // if (!this.result) {
      //   this.$toast.default(this.msg);
      //   this.level = this.level - 1;
      //   this.lowId = 0;
      //   if (this.visit <= 0) {
      //     this.$router.push(`/market_main_list/${this.id}`);
      //   }
      // }

      //현재 카테고리 안에 하위 카테고리 개수 this.totalPage
      //현재 카테고리 가 변하지 않았다면 총 개수를 유지해야 한다.
      if (this.tmpId !== this.id) {
        this.orgTotalCnt = this.totalPage;
      }

      this.tmpId = this.id;
      this.visit++;
      this.categoryPage++;
      if (this.categories.length > 0) {
        const idx = this.categories.findIndex(
          (x) => x.category_code === this.categoryCode
        );
        if (category) {
          this.$refs.goodsCategory.reflash(idx);
        }
      }
    },
    getMenu(num) {
      if (parseInt(num) === 1) {
        this.goMainList();
      }
      if (parseInt(num) == 3) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub_list {
  .box {
    .img {
      box-sizing: border-box;
    }
  }
}
.no_item {
  text-align: center;
}
</style>
